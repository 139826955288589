@use '~@moved/ui/src/sondheim/common' as *;

.layout {
  padding: $sp-24 ($sp-96+$sp-28);
  min-height: 100%;
  justify-content: center;
  align-items: center;
  @include responsive(mobile) {
    padding: 0 $sp-12 $sp-20;
  }
}
//IE11 targeted style to give task an explicit
//height so that vertical centering works
_:-ms-fullscreen, :root .layout {
  height: 0;
}
