@use '~@moved/ui/src/sondheim/common' as *;

.container {
  position: relative;
  padding: 0 $sp-48;
  text-align: center;
}

.leftAction {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.rightAction {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
