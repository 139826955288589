@use '~@moved/ui/src/sondheim/common' as *;

.content_wrapper {
  display: flex;
  gap: 25px;
  align-items: flex-start;
  justify-content: center;
  @include responsive(mobile) {
    flex-direction: column;
  }
}

.instructions {
  flex: 0 1 670px;
  min-width: 400px;
  @include responsive(mobile) {
    flex: 1 1 auto;
    min-width: 100%;
  }
}

.bill {
  flex: 0 0 392px;
  display: flex;
  flex-direction: column;
  @include responsive(mobile) {
    flex: 1 1 auto;
    min-width: 100%;
  }

  .divider {
    margin: 15px 0 16px;
    height: 1px;
    background: $borderSecondary;
  }

}

.content_block {
  display: flex;
  align-items: flex-start;
  gap: 44px;
  .left_column {
    flex: 0 0 auto;
  }
  .right_column {
    flex: 1 1 auto;
  }
}

.block_marker {
  width: 36px;
  height: 36px;
  display: flex;
  padding: 6px 2px;
  justify-content: center;
  align-items: center;
  border-radius: 999em;
  background: $backgroundSecondary;
  box-shadow: $shadow-2;
  &.info {
    border: 1px solid $borderTertiary;
    background: transparent;
    box-shadow: none;
  }
}

.app_links {
  display: flex;
  margin-top: 16px;
  gap: 20px;
}

.divider {
  margin: 28px 0;
  height: 1px;
  background: $borderTertiary;
}

.line_item {
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
