@use '~@moved/ui/src/balazs/common/all' as *;

.picker_section {
  @include breakpoint(xs) {
    padding: 0;
  }
}

.picker_container {
  margin: 0 auto;
  max-width: $container-max-width;
  padding: 0 12px 0 0;
  @include breakpoint(xs) {
    padding: 0;
  }
}

.keep_appointment {
  z-index: 99;
  position: relative;
  margin-bottom: 70px;
}
