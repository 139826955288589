@use '~@moved/ui/src/balazs/common/all' as *;
@use 'Profile.module';

.headliner {
  position: relative;
}

.backIcon {
  position: absolute;
  left: -64px;
  top: 50%;
  margin-top: -22px;
  @include breakpoint([xs]) {
    position: relative;
    left: auto;
    top: auto;
    margin: 0;
    display: inline-block;
  }
}

.formFields {
  max-width: 496px;
}
