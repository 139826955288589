@use '~@moved/ui/src/sondheim/common' as *;

.vendor {
  grid-area: vendor;
}

.speed {
  grid-area: speed;
}

.type {
  grid-area: type;
}

.price {
  grid-area: price;
}

.cta {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $sp-4;
  grid-area: cta;
  border-top: 1px solid $borderSecondary;
  padding-top: $sp-20;

  p {
    margin: unset;
  }
}

.wrapper {
  display: grid;
  gap: $sp-20;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "vendor price"
    "speed  type"
    "cta    cta"
}

@include breakpoints(lg,xl) {
  .wrapper {
    grid-template-columns: 2fr 1.75fr 1.5fr 2.25fr 1.75fr;
    grid-template-areas:
     "vendor speed type price cta"
  }

  .item:not(:first-child) {
    border-left: 1px solid $borderSecondary;
    padding-left: $sp-20;
  }

  .cta {
    border-top: none;
    padding-top: initial;
  }
}

.logo {
  max-height: 28px;
  max-width: 100%;
}

.badge {
  border-radius: $radius-4;
  padding: $sp-4 $sp-8;
}

.legalText {
  color: $contentSecondary;
  font-size: 10px;
  font-weight: 450;
  line-height: 12px;
}
