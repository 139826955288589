@use '~@moved/ui/src/sondheim/common' as *;

.container {
  @include responsive(desktop, tablet) {
    padding: 0 !important; // need full bleed for imagery
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: $sp-24;
  padding: $sp-28 $sp-32;
  max-width: 624px;
  @include responsive(mobile) {
    padding: 0;
    max-width: 100%;
  }
}

.sideImage {
  width: 392px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-right-radius: $radius-12;
  border-bottom-right-radius: $radius-12;
  @include responsive(mobile) { display: none; }
}

.customImage {
  position: relative;
  padding-top: $sp-48;
  padding-bottom: $sp-2;
}

.iconCard {
  padding: $sp-4;
  border-radius: $radius-full;
  position: absolute;
  bottom: 0;
  z-index: 2;
  &.origin { left: calc(50% - 100px); }
  &.destination { right: calc(50% - 100px); }
}

.arc {
  position: absolute;
  bottom: $sp-20;
  left: calc(50% - $sp-160/2);
  z-index: 1;
  height: 36px;
  width: $sp-160;
  border-radius: 50% / 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-style: dashed;
  border-left: 3px dashed $borderAccent;
  border-top: 3px dashed $borderAccent;
  border-right: 3px dashed $borderAccent;
  border-bottom: 0;
}

.tooltip {
  position: absolute;
  top: $sp-12;
  right: $sp-12;
  z-index: 3;
}
