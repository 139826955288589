@use '~@moved/ui/src/balazs/common/all' as *;

.picker_section {
  display: flex;
  flex-direction: column;
  gap: 64px;
  @include breakpoint(xs) {
    padding: 0;
  }
}

.picker_container {
  margin: 0 auto;
  max-width: $container-max-width;
  width: 100%;
  padding: 0 12px 0 0;
  @include breakpoint(xs) {
    padding: 0;
  }
}

.no_dates_title {
  font-size: $font-size-2;
}

.no_dates {
  font-size: $font-size-1;
}

.keep_appointment {
  z-index: 99;
  position: relative;
}
