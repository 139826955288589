@use "~@moved/ui/src/balazs/common/all" as *;

.panel {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-basis: 100%;
  padding: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  &.client_box {
    padding: 14px 14px 14px 0px;
  }

  &.pending {
    opacity: 0.4;
    pointer-events: none;
  }

  .splash {
    min-width: 64px;
    width: 64px;
    flex-basis: 64px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 48px;
    margin: 0 18px 0 0;
    border-radius: 4px;
    background-color: $black-10;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    outline: 1px solid rgba(21, 22, 24, 0.101961);
    outline-offset: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grow {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 33.3%;
      p {
        margin-bottom: 0;
        color: $black-100;
        font-size: $font-size-2;
        line-height: 20px;
      }
      span {
        color: $black-50;
        font-size: $font-size-1;
        line-height: 16px;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 18px;
    width: 120px;
    @include breakpoint([xs, sm]) {
      padding: 0;
      width: 100%;
      justify-content: center;
    }
  }

  .pill {
    font-size: $font-size-0;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 12px;
    padding: 3px 8px;
    font-weight: $med;
    white-space: nowrap;
    color: $black-60;
    background-color: $black-20;
    border: 1px solid $black-20;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;

    &.pending,
    &.pending-approval {
      background-color: $orange-20;
      border-color: $orange-20;
      color: $orange-60;
    }

    &.rejection_acknowledged {
      background-color: $orange-20;
      border-color: $orange-20;
      color: $orange-60;
    }

    &.submitted {
      background-color: $blue-20;
      border-color: $blue-20;
      color: $blue-60;
    }

    &.approved {
      background-color: $green-20;
      border-color: $green-20;
      color: $green-60;
    }

    &.cancelled,
    &.rejected {
      background-color: $red-20;
      border-color: $red-20;
      color: $red-60;
    }
  }

  @include breakpoint([xs, sm]) {
    flex-direction: column;
    padding: 10px;
    & > div {
      margin-bottom: 20px !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
