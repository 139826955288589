@use '~@moved/ui/src/sondheim/common' as *;

$widthExpanded: 324px;
$widthCollapsed: 80px;

$defaultTransition: 0.2s ease-out;

.wrapper {
  flex: 0 0 auto;
  width: $widthCollapsed;
  transition: width $defaultTransition;
  overflow: visible;
  position: relative;
  z-index: 2;
  @include responsive(mobile) {
    width: 100%;
    height: 100%;
  }
  &.locked {
    width: $widthExpanded;
    @include responsive(mobile) { width: 100% }
  }
}

.sidebar {
  position: absolute;
  inset: 0 auto 0 0;
  background: $backgroundSecondary;
  width: $widthCollapsed;
  transition: width $defaultTransition,
              box-shadow $defaultTransition;
  overflow: hidden;
  @include responsive(mobile) { width: 100% }
  .expanded & {
    width: $widthExpanded;
    box-shadow: $shadow-5;
    @include responsive(mobile) { width: 100% }
  }
  .locked & {
    box-shadow: none;
  }
}

// helper for all of the items that are hidden when collapsed
.hiddenCollapsed {
  transition: opacity $defaultTransition,
              visibility 0s 0.2s;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  overflow: hidden;
  .expanded & {
    opacity: 1;
    visibility: visible;
    transition: opacity $defaultTransition,
                visibility 0s;
  }
}

.topSection {
  padding: $sp-16 $sp-8;
  transition: padding $defaultTransition;
  .expanded & {
    padding: $sp-16;
  }
}

.logoBlock {
  padding: $sp-16;
  .expanded & {
    padding: $sp-16;
  }
}
.logo {
  height: 24px;
  width: auto;
  flex: 0 0 auto;
  display: block;
}
.lockIcon {
  color: $iconSingleEnabled;
  cursor: pointer;
  transition: transform $defaultTransition,
              color $defaultTransition,
              opacity $defaultTransition;
  &:hover {
    color: $iconSingleHover;
  }
  .locked & {
    color: $iconOnButton;
    transform: scaleX(-100%);
    opacity: 0;
  }
  .locked:hover & {
    opacity: 1;
  }
}

.navItem {
  padding: $sp-12 $sp-20;
  border-radius: $radius-8;
  color: $contentSecondary;
  transition: color $defaultTransition,
              background-color $defaultTransition;
  cursor: pointer;

  &:active, &:focus {
    color: $contentSecondary;
  }

  svg {
    color: $iconSingleEnabled;
    transition: color $defaultTransition;
  }

  svg.twoColor {
    --color-1: #{$white};
  }

  &.active {
    background-color: $backgroundOverlayLight;
    color: $contentPrimary;
    cursor: default;
    svg {
      color: $iconOnButton;
    }
  }

  &:hover {
    color: $contentPrimary;
    svg {
      color: $iconOnButton;
    }
  }

}

.label {
  transition: opacity 0.3s 0s,
              visibility 0s 0.3s,
              transform 0.3s 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateX(20px);
  white-space: nowrap;
  overflow: hidden;

  @for $i from 1 through 8 {
    .expanded .navItem:nth-of-type(#{$i}) & { transition-delay: #{100 * $i}ms; }
  }

  .expanded & {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

}

.profileSection {
  padding: $sp-8 $sp-8 $sp-20;
  transition: padding $defaultTransition;
  & > * {
    min-width: 0;
  }
  .expanded & {
    padding: $sp-8 $sp-16 $sp-20;
  }
}

.oval {
  height: $sp-48;
  width: $sp-48;
  flex: 0 0 auto;
  border-radius: 999em;
  background: linear-gradient(180deg, #C2C5C9 0%, #6F777F 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileMenu {
  flex: 0 0 auto;
  cursor: pointer;
  padding: $sp-12 $sp-8;
  border-radius: $radius-8;
  transition: background-color $defaultTransition,
              padding $defaultTransition;
  background-color: transparent;
  &:hover {
    background-color: $backgroundOverlayLight;
  }
  .expanded & {
    padding: $sp-12 $sp-20;
  }
}

.menuIcon {
  color: $iconSingleEnabled;
  transition: transform $defaultTransition,
              color $defaultTransition,
              opacity $defaultTransition,;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  overflow: hidden;
  .expanded & {
    opacity: 1;
    visibility: visible;
    transition: transform $defaultTransition,
                color $defaultTransition,
                opacity $defaultTransition,
                visibility 0s;
  }
  &:hover {
    color: $iconSingleHover;
  }
  .open & {
    color: $iconSingleActive;
    transform: rotate(180deg);
  }
}

// explore Button uses .hiddenCollapsed but needs custom transition timing
.exploreButton {
  width: 100%;
  transition: opacity 0.1s,
              visibility 0s 0.1s;
  .expanded & {
    transition: opacity 0.2s 0.4s;
  }
}
