@use '~@moved/ui/src/sondheim/common' as *;

/* ----------------------------------------
    Parallax splash image
---------------------------------------- */

.header {
  color: $white;
  position: relative;
  height: 412px;
  margin: $sp-12 $sp-12 0;
  border-radius: $radius-12;
  overflow: hidden;
  @include responsive(mobile) {
    height: 250px;
    margin: 0;
    border-radius: 0;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $backgroundOverlayDark;
  }
}

.splashWrapper {
  position: absolute;
  inset: -15px;
  z-index: -1;
  @include responsive(mobile) {
    // inset: 0;
  }
}

.splash {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-size: cover;
  background-color: $black-90;
  background-position: center;
  will-change: transform, filter;
  video {
    object-fit: cover;
  }
}

.contentWrapper {
  height: 100%;
  z-index: 2;
  position: relative;
  width: 1180px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.content {
  height: 100%;
  padding: $sp-64 0 ($sp-48 + $sp-8);
  display: flex;
  align-items: flex-end;
  @include responsive(mobile) { padding-bottom: 48px; }
  &.navigation {
    padding-bottom: 146px;
    @include responsive(mobile) { padding-bottom: 48px; }
  }
}
