@use '~@moved/ui/src/sondheim/common' as *;

$enterDuration: 200;
$enterDelay: 0;
$exitDuration: 200;
$exitDelay: 400;
$staggerDelay: 100;
$bottomPosition: $sp-24;
$rightPosition: $sp-24+$sp-28; // 56px

:export {
  enterDuration: $enterDuration;
  enterDelay: $enterDelay;
  exitDuration: $exitDuration;
  exitDelay: $exitDelay;
}

.container {
  position: fixed;
  bottom: $bottomPosition;
  right: $rightPosition;
  z-index: 20;
  @include responsive(mobile) { display:none; }
}

.contents {
  // position (collapsed)
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  // sizing (collapsed)
  width: 100%;
  height: 100%;
  padding: $sp-20 $sp-16;
  border-radius: $sp-48;
  // display
  background: $backgroundSecondary;
  box-shadow: $shadow-5;
  overflow: hidden;
  // children
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: $sp-24;
  // animations
  transition: height #{$exitDuration}ms ease-in-out #{$exitDelay}ms,
              width #{$exitDuration}ms ease-in-out #{$exitDelay}ms,
              bottom #{$exitDuration}ms ease-in-out #{$exitDelay}ms,
              border-radius #{$exitDuration}ms ease-in-out #{$exitDelay}ms;
  &.open {
    // position
    bottom: calc($sp-48 + $sp-20);
    // sizing
    width: 380px;
    height: calc(100vh - ($bottomPosition + $sp-48 + $sp-20 + $sp-24));
    // display
    border-radius: $radius-16;
    // transform: scale(1); // translate3d is to force GPU acceleration
    transition: height #{$enterDuration}ms ease-in-out #{$enterDelay}ms,
                width #{$enterDuration}ms ease-in-out #{$enterDelay}ms,
                bottom #{$enterDuration}ms ease-in-out #{$enterDelay}ms,
                border-radius #{$enterDuration}ms ease-in-out #{$enterDelay}ms;
    &.contactOnly {
      // sizing
      height: 425px;
    }
  }
}

.scaleIn {
  opacity: 0;
  transform: scale(0.75);
  transition: opacity 0.2s, transform 0.2s;
  .open & {
    opacity: 1;
    transform: scale(1);
  }
  @for $i from 1 through 20 {
    .open &:nth-child(#{$i}) {
      transition-delay: #{$staggerDelay * $i}ms;
    }
    &:nth-child(#{$i}) {
      transition-delay: #{$exitDelay - ($staggerDelay * $i)}ms;
    }
  }
}
