@use '~@moved/ui/src/balazs/common/all' as *;

.content {
  align-items: flex-start;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
}
.wrapper {
  max-width: 600px;
}
.title {
  margin-bottom: 30px;
}
.check {
  margin-top: 15px;
}
.summary {
  flex: 1 1 360px;
  max-width: 360px;
  @include breakpoint([xs]) {
    max-width: none;
    width: 100%;
  }
}
.radio_label {
  @include breakpoint([xs]) {
    font-size: $font-size-1;
    line-height: 150%;
    display: block;
  }
}
.amount_field {
  @include breakpoint([xs]) {
    margin-top: 20px;
  }
}
.mobile_summary {
  @include breakpoint([xs]) {
    margin-top: 30px;
  }
}
.summary_card {
  background: #fff;
  padding: 30px 30px;
  border-radius: 16px;
  box-shadow: 0 40px 50px 0 rgba(0,0,0,0.04);
  margin-bottom: 25px;
}
.section {
  padding-bottom: 25px;
  border-bottom: 1px solid $black-20;
  margin-bottom: 25px;
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
}
.pay_now {
  margin-top: 10px;
  line-height: 22px;
  &:first-child {
    margin-top: 0;
  }
}
.line_item {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: $font-size-3;
  gap: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
.line_label {
  text-align: left;
  flex: 0 0 auto;
  color: $black-40;
  &.black {
    color: $black-90;
  }
}
.line_value {
  text-align: right;
  flex: 1 0 auto;
  color: $black-40;
  &.black {
    color: $black-90;
  }
}
.line_icon {
  display: inline-block;
  padding: 3px;
  background: $black-20;
  border-radius: 50%;
  vertical-align: top;
  margin: 2px 15px 2px 0;
}
.line_subtext {
  display: block;
  margin-left: 34px;
  color: $black-40;
  font-size: 12px;
  line-height: 16px;
}
.collapse {
  cursor: pointer;
  .icon {
    transition: transform 0.3s, opacity 0.3s;
    vertical-align: top;
    display: inline-block;
    margin: 4px 0;
    opacity: 0.6;
  }
  &.invert {
    .icon {
      opacity: 1.0;
      transform: rotate(90deg);
    }
  }
}
