@use '@moved/ui/src/sondheim/common' as *;

.link {
  color: $purple-60;
  &:hover {
    color: $purple-70;
  }
}

.icon {
  --color-1: #{$iconMultiHelpfulLight} !important;
  --color-2: #{$iconMultiHelpful} !important;
}
