@use '~@moved/ui/src/sondheim/common' as *;

.content {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $sp-48;
  justify-content: center;
  align-items: center;
  @include responsive(mobile) {
    align-items: flex-start;
  }
}

.folder {
  max-width: 660px; // TODO: replace this with size token or remove
  width: 100%;
}
