@use '~@moved/ui/src/sondheim/common' as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: $sp-24;
  justify-content: center;
  align-items: center;
  button {
    max-width: 392px;
    min-width: 160px;
  }
  @include responsive(mobile) {
    flex-direction: column;
    gap: $sp-16;
    button {
      flex: 1 1 auto;
      width: 100%;
    }
  }
}
