@use '~@moved/ui/src/sondheim/common' as *;

.launcher {
  position: relative;
  padding: $sp-12;
  border-radius: $radius-full;
  box-shadow: $shadow-3;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
  cursor: pointer;
  overflow: hidden;
  background-color: $backgroundInversePrimary;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  &:hover, &:active {
    box-shadow: $shadow-5;
    transform: scale(1.25);
  }
  &:active {
    transition: box-shadow 0.1s, transform 0.1s;
    transform: scale(1);
  }
  &.open {
    background-color: $backgroundSecondary;
    box-shadow: $shadow-5;
  }
}
.openIcon {
  transition: all 0.2s 0.2s;
  .open & {
    transition: all 0.2s;
    opacity: 0;
  }
}
.closeIcon {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s;
  margin: 17px;
  .open & {
    opacity: 1;
    transition: all 0.2s 0.2s;
    transform: scale(1);
  }
}
