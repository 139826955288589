@use '~@moved/ui/src/sondheim/common' as *;

.wrapper {
  height: 100%;
  padding: $sp-24 0 0;
}

.container {
  height: 100%;
  width: 100%;
  & > div {
    height: 100%;
    width: 100%;
  }
}
