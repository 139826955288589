@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Book Movers flow confirmation screen
---------------------------------------- */

.content {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
}

/* --- Main Column --- */

.confirmation_content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include breakpoint([xs]) {
    width: 100%;
  }
}

.summary_fields {
  margin-top: 20px;
}
