@use '~@moved/ui/src/sondheim/common' as *;

.divider {
  border-top: 1px solid $borderSecondary;
  width: 100%;
}

.actions {
  justify-content: flex-end !important;
}

.sideImage {
  flex: 0 0 auto;
  position: relative;
  @include responsive(mobile) { display: none; }
  img {
    width: 312px;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
