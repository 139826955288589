@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Insurance provider options screen
---------------------------------------- */


.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.03);
  padding: 30px;
  display: block;
  color: $black-90;
  display: flex;
  flex-direction: column;
}
.cta {
  align-self: center;
  width: 100%;
  max-width: 300px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.logo {
  max-width: 160px;
  max-height: 50px;
  object-fit: contain;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  & > span {
    text-align: right;
  }
}
