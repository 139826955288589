@use '@moved/ui/src/sondheim/common' as *;

.icon {
  transition: transform 0.3s, color 0.3s;
  color: $contentSecondary;
  --color-1: currentColor;
  --color-2: currentColor;
  &.open {
    color: $contentPrimary;
    transform: rotate(-180deg);
  }
}

@keyframes clickBounce {
  0% { transform: scale(1.0); }
  10% { transform: scale(0.7); }
  40% { transform: scale(1.25); }
  60% { transform: scale(0.8); }
  80% { transform: scale(1.10); }
  100% { transform: scale(1.0); }
}

.helpfulButton {
  svg {
    color: $contentPrimary;
    --color-1: currentColor !important;
    --color-2: currentColor;
    transition: color 0.2s;
  }
  &.active {
    svg {
      animation: clickBounce 1.0s 1 ease;
      color: $iconSingleActive;
    }
  }
}
