@use '~@moved/ui/src/sondheim/common' as *;

.parallaxScroller {
  @include scrollable;
  height: 100%;
}

.buildingLogo {
  filter: brightness(0) invert(1);
  img {
    display: block;
    max-height: 80px;
    max-width: 350px;
    @include responsive(mobile) {
      max-height: 60px;
      max-width: 265px;
    }
  }
}

.dashboard {
  position: relative;
  padding-bottom: $sp-64;
  z-index: 1;
}
