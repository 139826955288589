@use '~@moved/ui/src/balazs/common/all' as *;

.locationMap {
  flex: 0 0 auto;
  height: 48px;
  width: 48px;
  border: 1px solid $border-color;
  border-radius: 3px;
  background-size: cover;
  background-position: center;
  background-color: $black-10;
}
